import { inspectContract } from "./contract";
import { getContracts } from "./covalent";
import { providerForChain } from "./providers";
import { ChainToken, Token } from "../types/tokens";
import { BigNumber } from "ethers";

function chainToken(chainId: number, balance: BigNumber): ChainToken {
    if (chainId === 137) {
        return {
            type: 'chain',
            chainId,
            name: 'Polygon Matic',
            symbol: 'MATIC',
            decimals: 18,
            balance: balance
        }
    }
    return {
        type: 'chain',
        chainId,
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
        balance: balance
    }
}

async function inspectChain(address: string, chainId: number, fiatCurrency: string, emitToken: (token: Token) => void) {
    const provider = providerForChain(chainId);
    const balance = await provider.getBalance(address);
    emitToken(chainToken(chainId, balance));
    const contracts = await getContracts(address, chainId, fiatCurrency);
    for (let contract in contracts) {
        inspectContract(address, provider, chainId, contract, contracts[contract], emitToken);
    }
}

export { inspectChain }
