import { JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { FungibleToken } from "../../types/tokens";

const ABI = [
    // Read-Only Functions
    "function balanceOf(address owner) view returns (uint256)",
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",
    "function name() view returns (string)",

    // Authenticated Functions
    "function transfer(address to, uint amount) returns (bool)",

    // Events
    "event Transfer(address indexed from, address indexed to, uint amount)"
];

async function getEip20Token(address: string, provider: JsonRpcProvider, chainId: number, contract: string, emitToken: (token: FungibleToken) => void) {
    try {
        const eip20 = new ethers.Contract(contract, ABI, provider);
        emitToken({
            type: 'eip20',
            address,
            chainId,
            contract,
            decimals: await eip20.decimals(), // Comes first because is the least common function call in other contracts
            name: await eip20.name(),
            symbol: await eip20.symbol(),
            balance: await eip20.balanceOf(address)
        });
    }
    catch { }
}

export { getEip20Token }
