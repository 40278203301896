import { useEffect, useState } from "react";
import { inspectChain } from "../model/chain";
import { Token } from "../types/tokens";
import Coins from "./Coins";
import Header from "./Header";
import NFTs from "./NFTs";

const App: React.FC = () => {
    const address = process.env.TEST_ADDRESS;
    const [tokens, setTokens] = useState<Token[]>([]);

    const receiveToken = (token: Token) => {
        setTokens(tokens => [ ...tokens, token ]);
    }

    useEffect(() => {
        setTokens([]);
        inspectChain(address, 1, 'EUR', receiveToken);
        inspectChain(address, 137, 'EUR', receiveToken);
    }, [ address ]);

    return <>
        <Header address={address} />
        <main>
            <Coins tokens={tokens} />
            <NFTs tokens={tokens} />
        </main>
    </>;
}

export default App;
