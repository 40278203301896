import { JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { NonFungibleToken } from "../../types/tokens";
import { interfaceIds, supportsInterface } from "../interfaces/eip165";

const ABI = [
    // Read-Only Functions
    "function ens() view returns (address)"
];

async function isEns(provider: JsonRpcProvider, chainId: number, contract: string): Promise<boolean> {
    if (chainId !== 1) return false;
    if (!await supportsInterface(contract, provider, interfaceIds.ENSFunction)) {
        const ens = new ethers.Contract(contract, ABI, provider);
        try {
            await ens.ens();
        }
        catch {
            return false;
        }
    }
    return true;
}

function ensMetadata(token: NonFungibleToken): string {
    if (token.chainId !== 1) return;
    token.collectionName = 'Ethereum Name Service';
    token.collectionSymbol = 'ENS';
    token.uri = 'https://metadata.ens.domains/mainnet/' + token.contract + '/' + token.tokenId.toString();
}

export { isEns, ensMetadata }
