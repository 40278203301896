import { JsonRpcProvider } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { RawLogEvent } from "../../types/covalent";
import { NonFungibleToken } from "../../types/tokens";
import { normalizeUri } from '../../utils/uri';
import { ensMetadata, isEns } from "../known-contracts/ens";
import { unstoppableDomainMetadata } from "../known-contracts/unstoppable-domains";
import { interfaceIds, supportsInterface } from "./eip165";

const ABI = [
    // Read-Only Functions
    "function balanceOf(address _owner) view returns (uint256)",
    "function ownerOf(uint256 _tokenId) view returns (address)",
    "function symbol() view returns (string)",
    "function name() view returns (string)",
    "function getApproved(uint256 _tokenId) view returns (address)",
    "function isApprovedForAll(address _owner, address _operator) view returns (bool)",

    // Authenticated Functions
    "function safeTransferFrom(address _from, address _to, uint256 _tokenId, bytes data)",
    "function safeTransferFrom(address _from, address _to, uint256 _tokenId)",
    "function transferFrom(address _from, address _to, uint256 _tokenId)",
    "function approve(address _approved, uint256 _tokenId)",
    "function setApprovalForAll(address _operator, bool _approved)",

    // Events
    "event Transfer(address indexed _from, address indexed _to, uint256 indexed _tokenId)",
    "event Approval(address indexed _owner, address indexed _approved, uint256 indexed _tokenId)",
    "event ApprovalForAll(address indexed _owner, address indexed _operator, bool _approved)"
];

const ABIEnumerable = [
    // Read-Only Functions
    "function totalSupply() view returns (uint256)",
    "function tokenByIndex(uint256 _index) view returns (uint256)",
    "function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)"
];

const ABIMetadata = [
    // Read-Only Functions
    "function name() view returns (string _name)",
    "function symbol() view returns (string _symbol)",
    "function tokenURI(uint256 _tokenId) view returns (string)"
];

async function getMetadata(token: NonFungibleToken, emitToken: (token: NonFungibleToken) => void) {
    if (token.uri) {
        let json: {
            name?: string,
            description?: string,
            image?: string,
            image_url?: string
        } = null;
        try {
            const response = await fetch(normalizeUri(token.uri));
            json = await response.json();
        }
        catch { }
        if (json === null) {
            try {
                const response = await fetch('metadata', { method: 'POST', body: normalizeUri(token.uri) });
                json = await response.json();
            }
            catch { }
        }
        if (json !== null) {
            if (json.name) {
                token.name = json.name;
            }
            if (json.description) {
                token.description = json.description;
            }
            if (json.image) {
                token.image = normalizeUri(json.image);
            }
            else if (json.image_url) {
                token.image = normalizeUri(json.image_url);
            }
            unstoppableDomainMetadata(token, json);
        }
    }
    emitToken(token);
}

async function getEip721Metadata(provider: JsonRpcProvider, token: NonFungibleToken, emitToken: (token: NonFungibleToken) => void) {
    if (await supportsInterface(token.contract, provider, interfaceIds.Eip721Metadata)) {
        try {
            const eip721Metadata = new ethers.Contract(token.contract, ABIMetadata, provider);
            token.collectionName = await eip721Metadata.name();
            token.collectionSymbol = await eip721Metadata.symbol();
            token.uri = await eip721Metadata.tokenURI(token.tokenId);
        }
        catch { }
    }
    else if (await isEns(provider, token.chainId, token.contract)) {
        ensMetadata(token);
    }
    getMetadata(token, emitToken);
}

async function getEip721EventsTokens(address: string, provider: JsonRpcProvider, chainId: number, contract: string, events: RawLogEvent[], emitToken: (token: NonFungibleToken) => void) {
    if (!await supportsInterface(contract, provider, interfaceIds.Eip721)) return;
    const eip721 = new ethers.Contract(contract, ABI, provider);
    const hexTokenIds = events.filter(e => e.topics[0] === '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' && e.topics[2] === '0x000000000000000000000000' + address.substring(2)).map(el => el.topics[3]);
    hexTokenIds.forEach(async hexTokenId => {
        try {
            const tokenOwner = await eip721.ownerOf(hexTokenId);
            if (tokenOwner.toLowerCase() === address.toLowerCase()) {
                getEip721Metadata(provider, {
                    type: 'eip721',
                    address,
                    chainId,
                    contract,
                    tokenId: BigNumber.from(hexTokenId)
                }, emitToken);
            }
        }
        catch { }
    });
}

async function getEip721EnumerableTokens(address: string, provider: JsonRpcProvider, chainId: number, contract: string, emitToken: (token: NonFungibleToken) => void) {
    if (!await supportsInterface(contract, provider, interfaceIds.Eip721) || !await supportsInterface(contract, provider, interfaceIds.Eip721Enumerable)) return;
    const eip721 = new ethers.Contract(contract, ABI, provider);
    const balance = await eip721.balanceOf(address);
    if (balance > 0) {
        const eip721Enumerable = new ethers.Contract(contract, ABIEnumerable, provider);
        for (let i = 0; i < balance; i++) {
            const tokenId = await eip721Enumerable.tokenOfOwnerByIndex(address, i);
            getEip721Metadata(provider, {
                type: 'eip721',
                address,
                chainId,
                contract,
                tokenId
            }, emitToken);
        }
    }
}

async function getEip721Tokens(address: string, provider: JsonRpcProvider, chainId: number, contract: string, events: RawLogEvent[], emitToken: (token: NonFungibleToken) => void) {
    if (!await supportsInterface(contract, provider, interfaceIds.Eip721)) return;
    try {
        if (await supportsInterface(contract, provider, interfaceIds.Eip721Enumerable)) {
            getEip721EnumerableTokens(address, provider, chainId, contract, emitToken)
        }
        else {
            getEip721EventsTokens(address, provider, chainId, contract, events, emitToken);
        }
    }
    catch { }
}

export { getEip721Tokens }
