import { ethers } from "ethers";
import { Token } from "../types/tokens";

const Coin: React.FC<{ token: Token }> = ({ token }) => {
    if (token.type === 'eip721' || token.type === 'eip1155') return <></>
    const image = token.chainId === 1 ? 'images/eth.svg' : 'images/polygon.svg';
    return <article className="coins__item coin">
        <img src={image} className="coin__logo" />
        <section className="coin__data coin-data">
            <div className="coin-data__name">{ token.name }</div>
            <div className="coin-data__fiat-value">€ 0,00</div>
        </section>
        <section className="coin__value coin-value">
            <div className="coin-value__crypto">{ ethers.utils.formatUnits(token.balance, token.decimals) } { token.symbol }</div>
            <div className="coin-value__fiat">€ 0,00</div>
        </section>
    </article>;
}

export default Coin;
