import { BigNumber } from "ethers"

type Token = ChainToken | FungibleToken | NonFungibleToken | SemiFungibleToken;

type BaseToken = {
    address: string,
    chainId: number,
    contract: string
}

type ChainToken = {
    type: 'chain',
    chainId: number,
    name: string,
    symbol: string,
    decimals: number,
    balance: BigNumber
}

type FungibleToken = BaseToken & {
    type: 'eip20',
    name: string,
    symbol: string,
    decimals: number,
    balance: BigNumber
}

type NonFungibleToken = BaseToken & {
    type: 'eip721',
    tokenId: BigNumber,
    collectionName?: string,
    collectionSymbol?: string,
    uri?: string,
    name?: string,
    image?: string,
    description?: string
}

type SemiFungibleToken = BaseToken & {
    type: 'eip1155',
    tokenId: BigNumber,
    collectionName?: string,
    collectionSymbol?: string,
    balance: BigNumber,
    uri?: string
    name?: string,
    image?: string,
    description?: string,
    decimals?: number
}

function tokenKey(token: Token): string {
    return token.chainId.toString() + '-' + (token.type !== 'chain' ? token.contract : '') + '-' + (token.type === 'eip721' || token.type === 'eip1155' ? token.tokenId.toString() : '');
}

export { Token, ChainToken, FungibleToken, NonFungibleToken, SemiFungibleToken, tokenKey }
