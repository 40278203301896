import { JsonRpcProvider } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { getSystemErrorMap } from "util";
import { RawLogEvent } from "../../types/covalent";
import { NonFungibleToken, SemiFungibleToken } from "../../types/tokens";
import { normalizeUri } from "../../utils/uri";
import { interfaceIds, supportsInterface } from "./eip165";

const ABI = [
    // Read-Only Functions
    "function balanceOf(address _owner, uint256 _id) view returns (uint256)",
    "function balanceOfBatch(address[] calldata _owners, uint256[] calldata _ids) view returns (uint256[] memory)",
    "function isApprovedForAll(address _owner, address _operator) view returns (bool)",

    // Authenticated Functions
    "function safeTransferFrom(address _from, address _to, uint256 _id, uint256 _value, bytes calldata _data)",
    "function safeBatchTransferFrom(address _from, address _to, uint256[] calldata _ids, uint256[] calldata _values, bytes calldata _data)",
    "function setApprovalForAll(address _operator, bool _approved)",

    // Events
    "event TransferSingle(address indexed _operator, address indexed _from, address indexed _to, uint256 _id, uint256 _value)",
    "event TransferBatch(address indexed _operator, address indexed _from, address indexed _to, uint256[] _ids, uint256[] _values)",
    "event ApprovalForAll(address indexed _owner, address indexed _operator, bool _approved)",
    "event URI(string _value, uint256 indexed _id)"
];

const ABINonStandardMetadata = [
    // Read-Only Functions
    "function name() view returns (string _name)",
    "function symbol() view returns (string _symbol)"
];

const ABIMetadata = [
    // Read-Only Functions
    "function uri(uint256 _id) view returns (string memory)"
];

async function getMetadata(token: SemiFungibleToken, emitToken: (token: SemiFungibleToken) => void) {
    if (token.uri) {
        let json: {
            name?: string,
            description?: string,
            image?: string,
            image_url?: string,
            decimals?: number
        } = null;
        try {
            const response = await fetch(normalizeUri(token.uri));
            json = await response.json();
        }
        catch { }
        if (json === null) {
            try {
                const response = await fetch('metadata', { method: 'POST', body: normalizeUri(token.uri) });
                json = await response.json();
            }
            catch { }
        }
        if (json !== null) {
            if (json.name) {
                token.name = json.name;
            }
            if (json.description) {
                token.description = json.description;
            }
            if (json.image) {
                token.image = normalizeUri(json.image);
            }
            else if (json.image_url) {
                token.image = normalizeUri(json.image_url);
            }
            if (json.decimals) {
                token.decimals = json.decimals;
            }
        }
    }
    emitToken(token);
}

async function getEip1155MetadataURI(contract: string, provider: JsonRpcProvider, token: SemiFungibleToken, emitToken: (token: SemiFungibleToken) => void) {
    // Speculating on Non-Standard Interface implementation
    try {
        const eip1155Metadata = new ethers.Contract(token.contract, ABINonStandardMetadata, provider);
        token.collectionName = await eip1155Metadata.name();
        token.collectionSymbol = await eip1155Metadata.symbol();
    }
    catch { }
    if (await supportsInterface(token.contract, provider, interfaceIds.Eip1155MetadataURI)) {
        try {
            const eip1155MetadataURI = new ethers.Contract(contract, ABIMetadata, provider);
            const id = token.tokenId.toHexString().substring(2).toLowerCase().padStart(64, '0');
            token.uri = await eip1155MetadataURI.uri(token.tokenId);
            token.uri = token.uri.replace('{id}', id);
        }
        catch (error) { }
    }
    getMetadata(token, emitToken);
}

async function getEip1155Tokens(address: string, provider: JsonRpcProvider, chainId: number, contract: string, events: RawLogEvent[], emitToken: (token: SemiFungibleToken) => void) {
    if (!await supportsInterface(contract, provider, interfaceIds.Eip1155)) return;
    try {
        const tokenEvents = events.filter(event => event.topics.length === 4 && (event.topics[2] === '0x000000000000000000000000' + address.substring(2) || event.topics[3] === '0x000000000000000000000000' + address.substring(2)));
        const addresses: string[] = [];
        const tokenIds: string[] = [];
        for (let i = 0; i < tokenEvents.length; i++) {
            if (tokenEvents[i].data.length === 130) {
                addresses.push(address);
                tokenIds.push(tokenEvents[i].data.substring(0, 66));
            }
        }
        const eip1155 = new ethers.Contract(contract, ABI, provider);
        const balances = await eip1155.balanceOfBatch(addresses, tokenIds);
        for (let i = 0; i < balances.length; i++) {
            if (balances[i].toNumber() > 0) {
                getEip1155MetadataURI(contract, provider, {
                    type: 'eip1155',
                    address,
                    chainId,
                    contract,
                    tokenId: BigNumber.from(tokenIds[i]),
                    balance: balances[i]
                }, emitToken);
            }
        }
    }
    catch { }
}

export { getEip1155Tokens }
