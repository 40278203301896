import { Token, tokenKey } from "../types/tokens";
import Coin from "./Coin";

const Coins: React.FC<{ tokens: Token[] }> = ({ tokens }) => {
    const fungibleTokens = tokens.filter(token => token.type !== 'eip721' && token.type !== 'eip1155');
    return <section className="coins">
        <h2 className="coins__title">💰 Coins</h2>
        <section className="coins__list">
            { fungibleTokens.map(token => <Coin key={tokenKey(token)} token={token} />) }
        </section>
    </section>
}

export default Coins;
