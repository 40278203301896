import { NonFungibleToken } from "../../types/tokens";

function unstoppableDomainMetadata(token: NonFungibleToken, metadata: any) {
    if (token.collectionSymbol !== 'UD') return;
    if (metadata.image_data) {
        token.image = 'data:image/svg+xml;utf8,' + encodeURIComponent(metadata.image_data);
    }
}

export { unstoppableDomainMetadata }
