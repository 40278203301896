import { RawLogEvent } from "../types/covalent"

type LogEvent = {
    sender_address: string,
    raw_log_topics: RawLogEvent,
    raw_log_data: string
}

type Transaction = {
    log_events?: LogEvent[]
}

type TransactionData = {
    items: Transaction[]
}

type Pagination = {
    has_more: boolean
}

type TransactionsResponse = {
    data?: TransactionData,
    pagination?: Pagination
}

async function getPage(address: string, chainId: number, fiatCurrency: string, emitter: (event: LogEvent) => void, page: number): Promise<boolean> {
    const response = await fetch('https://api.covalenthq.com/v1/' + chainId + '/address/' + address + '/transactions_v2/?quote-currency=' + fiatCurrency + '&format=JSON&block-signed-at-asc=false&no-logs=false&page-number=' + page + '&key=' + process.env.COVALENT_API_KEY);
    const json: TransactionsResponse = await response.json();
    for (let transaction of json.data.items) {
        if (transaction.log_events) {
            for (let logEvent of transaction.log_events) {
                emitter(logEvent);
            }
        }
    } 
    return json.pagination?.has_more ?? false;
}

async function getContracts(address: string, chainId: number, fiatCurrency: string): Promise<Map<string, RawLogEvent[]>> {
    let contracts: Map<string, RawLogEvent[]> = new Map<string, RawLogEvent[]>();
    let hasMore = false;
    let page = 0;
    do {
        hasMore = await getPage(address, chainId, fiatCurrency, (event) => {
            if (!contracts.hasOwnProperty(event.sender_address)) {
                contracts[event.sender_address] = [];
            }
            contracts[event.sender_address].push({
                topics: event.raw_log_topics,
                data: event.raw_log_data
            });
        }, page);
        page++;
    }
    while (hasMore);
    return contracts;
}

export { getContracts };
