import { JsonRpcProvider } from "@ethersproject/providers";
import { interfaceIds, supportsInterface } from "./interfaces/eip165";
import { getEip20Token } from "./interfaces/eip20";
import { Token } from "../types/tokens";
import { RawLogEvent } from "../types/covalent";
import { getEip721Tokens } from "./interfaces/eip721";
import { getEip1155Tokens } from "./interfaces/eip1155";

async function inspectContract(address: string, provider: JsonRpcProvider, chainId: number, contract: string, events: RawLogEvent[], emitToken: (token: Token) => void) {
    if (await supportsInterface(contract, provider, interfaceIds.Eip721)) {
        await getEip721Tokens(address, provider, chainId, contract, events, emitToken);
    }
    else if (await supportsInterface(contract, provider, interfaceIds.Eip1155)) {
        await getEip1155Tokens(address, provider, chainId, contract, events, emitToken);
    }
    else {
        await getEip20Token(address, provider, chainId, contract, emitToken);
    }
}

export { inspectContract }
