import { JsonRpcProvider } from "@ethersproject/providers";

let providersCache: Map<number, JsonRpcProvider> = new Map<number, JsonRpcProvider>();

function createProviderForChain(chainId: number): JsonRpcProvider {
    switch (chainId) {
        case 137: return new JsonRpcProvider('https://rpc.ankr.com/polygon');
        default: return new JsonRpcProvider('https://rpc.ankr.com/eth');
    }
}

function providerForChain(chainId: number): JsonRpcProvider {
    if (!providersCache.hasOwnProperty(chainId)) {
        providersCache[chainId] = createProviderForChain(chainId);
    }
    return providersCache[chainId];
}

export { providerForChain }
