import { Token, tokenKey } from "../types/tokens";
import NFT from "./NFT";

const NFTs: React.FC<{ tokens: Token[] }> = ({ tokens }) => {
    const nonFungibleTokens = tokens.filter(token => token.type === 'eip721' || token.type === 'eip1155');
    return <section className="nfts">
        <h2 className="nfts__title">🖼 NFTs</h2>
        <section className="nfts__list">
            { nonFungibleTokens.map(token => <NFT key={tokenKey(token)} token={token} />) }
        </section>
    </section>
}

export default NFTs;
