import { JsonRpcProvider } from "@ethersproject/providers";
import { JsonRpcRequest, JsonRpcResponse } from '../../types/json-rpc';

const interfaceIds = {
    Eip165: '0x01ffc9a7',
    Eip721: '0x80ac58cd',
    Eip721Metadata: '0x5b5e139f',
    Eip721Enumerable: '0x780e9d63',
    Eip1155: '0xd9b67a26',
    Eip1155MetadataURI: '0x0e89341c',
    ENSFunction: '0x3f15457f',
    Invalid: '0xffffffff'
}

let requestId = 0;
async function rawSupportsInterface(contract: string, provider: JsonRpcProvider, interfaceId: string): Promise<boolean> {
    try {
        const requestData: JsonRpcRequest = {
            "jsonrpc": "2.0",
            "method": "eth_call",
            "params": [
                {
                    "to": contract,
                    "data": "0x01ffc9a7" + interfaceId.substring(2) + "00000000000000000000000000000000000000000000000000000000"
                },
                "latest"
            ],
            "id": requestId++
        }
        const response = await fetch(provider.connection.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        const json: JsonRpcResponse = await response.json();
        return json.result === '0x0000000000000000000000000000000000000000000000000000000000000001';
    }
    catch { }
    return false;
}

let supportsInterfaceCache: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
async function cachedSupportsInterface(contract: string, provider: JsonRpcProvider, interfaceId: string): Promise<boolean> {
    if (!supportsInterfaceCache.hasOwnProperty(contract)) {
        supportsInterfaceCache[contract] = new Map<string, boolean>();
    }
    if (!supportsInterfaceCache[contract].hasOwnProperty(interfaceId)) {
        supportsInterfaceCache[contract][interfaceId] = await rawSupportsInterface(contract, provider, interfaceId);
    }
    return supportsInterfaceCache[contract][interfaceId];
}

async function isEip165(contract: string, provider: JsonRpcProvider): Promise<boolean> {
    const supportsSupportsInterface = await cachedSupportsInterface(contract, provider, interfaceIds.Eip165);
    const supportsInvalidInterface = await cachedSupportsInterface(contract, provider, interfaceIds.Invalid);
    return supportsSupportsInterface && !supportsInvalidInterface;
}

async function supportsInterface(contract: string, provider: JsonRpcProvider, interfaceId: string): Promise<boolean> {
    if (!await isEip165(contract, provider)) return false;
    return await cachedSupportsInterface(contract, provider, interfaceId);
}

export { interfaceIds, supportsInterface }
