import { Token } from "../types/tokens";

const NFT: React.FC<{ token: Token }> = ({ token }) => {
    const image = token.chainId === 1 ? 'images/eth.svg' : 'images/polygon.svg';
    if (token.type === 'eip721') {
        return <article className="nfts__item nft">
            <img src={token.image} alt="" className="nft__image" />
            <section className="nft__data">{ token.name ? token.name : token.tokenId.toString() }</section>
            <section className="nft__collection nft-collection"><img src={image} className="nft__chain-logo" /><span className="nft-collection__name">{ (token.collectionName ? token.collectionName : token.contract) + (token.collectionSymbol ? ' (' + token.collectionSymbol + ')' : '') }</span></section>
        </article>;
    }
    if (token.type === 'eip1155') {
        return <article className="nfts__item nft">
            <img src={token.image} alt="" className="nft__image" />
            <section className="nft__data">{ (token.balance.toBigInt() > 1 ? token.balance.toString() + ' x ' : '') + (token.name ? token.name : token.tokenId.toString()) }</section>
            <section className="nft__collection nft-collection"><img src={image} className="nft__chain-logo" /><span className="nft-collection__name">{ (token.collectionName ? token.collectionName : token.contract) + (token.collectionSymbol ? ' (' + token.collectionSymbol + ')' : '') }</span></section>
        </article>;
    }
    return <></>
}

export default NFT;
